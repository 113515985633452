<template>
  <div class="list">
    <div class="nav">
      <div
        class="item"
        @click="handleClick(1)"
        :class="{ activeNav: active === 1 }"
      >
        {{ $t('contract').ccjl }}
      </div>
      <div
        class="item"
        @click="handleClick(2)"
        :class="{ activeNav: active === 2 }"
      >
        {{ $t('contract').ddjl }}
      </div>
    </div>
    <!-- <div class="navheader">
      <div class="item" v-for="item in nowNavheader" :key="item">
        {{ item }}
      </div>
    </div> -->
    <div class="content" v-if="active === 1">
      <van-list
        class="list-box"
        ref="list"
        v-model="oloading"
        :finished="ofinished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getOrderList"
      >
        <div class="item" v-for="item in Orderlist" :key="item.Id">
          <div>
            {{ item.Amount }}
          </div>
          <div>
            {{ item.StartInvestment }}
          </div>
          <div>
            {{ productInfo.Price }}
          </div>
          <!-- <div>
            {{ showPrice(item) }}
          </div> -->
          <div>           
            <van-count-down
              :time="getTime(item)"
              format="sss"
              @finish="finish(item)"
            />
          </div>
        </div>
      </van-list>
    </div>
    <div class="content" v-else>
      <van-list
        class="list-box second"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <div class="item" v-for="item in list" :key="item.Id">
          <div class="one"><div>{{item.ProductName}}/USDT </div>
          <div class="tag">
             
            {{item.Direction == 'Rise'? $t('contract').mz:$t('contract').md}}
          </div> {{ $t('lockming').js }} {{new Date(item.EndTime).toLocaleString() }}</div>
          <div class="two">
            <div class="info">
              <div class="title" >{{ $t('public').amount }}</div>
              <div class="content1"> {{ item.Amount }}</div>
           
          </div>
          <div class="info">
            <div class="title">{{ $t('public').startInvestment }}</div>
            <div class="content1"> {{ item.StartInvestment }}</div>
           
          </div>
          <div class="info">
            <div class="title">{{ $t('public').endInvestment }}</div>
            <div class="content1">  {{ item.EndInvestment }}</div>
          
          </div>
          <div class="info" style="color:#02c289" :class="{ up: item.Profit < 0 }">
            <div class="title">{{ $t('public').profit }}</div>
            <div class="content1">  {{ item.Profit }}</div>
          
          </div>
          </div>
        
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import productApi from '@/api/product'
import { EventBus } from '@/utils/event-bus'
export default {
  props: ['productInfo'],
  data() {
    return {
      active: 1,
      list: [],
      Orderlist: [],
      navheader: [
        this.$t('contract').sl,
        this.$t('contract').gmj,
        this.$t('contract').dqj,
        // this.$t('contract').yjyk,
        this.$t('contract').djs
      ],
      nowNavheader: [],
      orderheader: [
        this.$t('contract').sl,
        this.$t('contract').gmj,
        this.$t('contract').gbjg,
        // this.$t('contract').sc,
        this.$t('contract').pal
      ],
      form: {
        page: 0,
        size: 10,
        productId: null
      },
      oform: {
        page: 1,
        size: 10,
        productId: null
      },
      loading: false,
      finished: false,
      oloading: false,
      ofinished: false
    }
  },
  watch: {
    productInfo(val) {
      this.oform.productId = val.Id
      this.form.productId = val.Id
    }
  },
  mounted() {
    this.nowNavheader = this.navheader
    EventBus.$on('createOrder', () => {
      this.handleClick(1)
    })
  },
  methods: {
    reset() {
      this.list = []
      this.Orderlist = []
      setTimeout(() => {
        if (this.active !== 1) {
          this.form.page = 1
          this.finished = false
        } else {
          this.oform.page = 1
          this.ofinished = false
        }
      }, 100)
    },
    getTime(item) {
      if (item && item.EndTime) {
        // console.log(item.EndTime)
        // console.log(new Date(item.EndTime).toLocaleString())

        // return new Date(item.EndTime).toLocaleString()
        const date = new Date().getTime()
        return Date.parse(item.EndTime) - date
      }
    },
    async getList() {
      const res = await productApi.getRecordList(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      this.loading = false
    },
    async getOrderList() {
      const res = await productApi.getOrderList(this.oform)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.Orderlist.push.apply(this.Orderlist, data)
        this.oform.page++
      }
      if (length !== this.oform.size) {
        this.ofinished = true
      }
      this.oloading = false
    },
    showPrice(item) {
      if (item.Direction === 'Rise') {
        return item.Amount * item.ProfitRate
      } else {
        return item.Amount * item.LoseRate
      }
    },
    finish(item) {
      if (this.finishOrder !== item.OrderNo) {
        this.finishOrder = item.OrderNo
        setTimeout(() => {
          this.handleClick(1)
        }, 2000)
      }
    },
    handleClick(index) {
      this.active = index
      if (index === 1) {
        this.nowNavheader = this.navheader
        this.oform.page = 1
        this.Orderlist = []
        this.ofinished = false
      } else {
        this.nowNavheader = this.orderheader
        this.form.page = 1
        this.list = []
        this.finished = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.list {
  background: #131f30;
}
.nav {
  display: flex;
  .item {
    height: 42px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .activeNav {
    border-bottom: 2px solid #e143ac;
  }
}
.navheader {
  height: 40px;
  display: flex;
  font-size: 14px;
  border-bottom: 1px solid #12151c;
  .item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.content {
  padding-bottom: 71px;
}
.list-box {
  height: 400px;
  overflow: scroll;
 
  .item {
   
    display: flex;
    align-items: center;
    border-bottom: 1px solid #1f253c;
    div {
      width: 25%;
      display: flex;
      color: #fff;
      height: 40px;
      align-items: center;
      justify-content: center;
    }
  }
}
.second {
  .item {
    flex-direction: column;
    padding-bottom: 15px;
    .one {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
    .two {
      width: 100%;
      .info {
        height: unset;
        display: flex;
        flex-direction: column;
        align-items: center; 
        .title {
          width: 100% !important;
        }
        .content1 {
          padding-bottom: unset;
        }
      }
    }
  }
}

</style>
